<template>
	<!-- <div> -->
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div> -->
		<!-- <div class="top_action no_print">
			<el-button type="primary" plain icon="el-icon-download" @click="exportExcel()">Excel</el-button>
		</div> -->
		<div class="printMain body-bg">
			<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
			<div style="" v-loading="loading_load">
			
				<div class="printer_con">
					<h1 class="printer_tit" style="">
						<div>Devanning List</div>
								
					</h1>
					<div class="" style="display: flex;justify-content: space-between; align-items: center; ">
						<div>
							<barcode :code="priterData.sysNo" :options="{height:50}"></barcode>
						</div>
						<div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; ">
							<div style="margin:5px 0;">Customer#&nbsp;{{priterData.cusName}}</div>
							<div style="margin:5px 0;">Packing No#&nbsp;{{priterData.packingNo}}</div>
							<!-- <div style="margin:5px 0;">Date#&nbsp;{{priterData.commitDate}}</div> -->
							<div style="margin:5px 0;">Type#&nbsp;<span>{{priterData.packTypeName}}</span></div>
							<!-- <div style="margin:5px 0;">Pallte#&nbsp;<span>{{priterData.pallteCount}}</span></div> -->
							
							<!-- <div>{{priterData.cusName}}</div> -->
						</div>
						
					</div>
					
					<div class="printer_block">
						
						<!-- <div class="count-prt-con">
							<table class="simTable printerTable printerTableSummary" style="">
								<tr>
									<td colspan="4">
										<div class="titleItem">
											<strong>Summary</strong>
										</div>
									</td>
								</tr>
								<tbody class="text_small">
									<tr v-for="(item ,index) in priterData.totalDetailArr" :key="index" style="">
										<td width="150"><span>Mark:&nbsp;</span><span>{{item.ctnMark}}</span></td>
										<td width="150">
											<span>Qty:&nbsp;{{item.ctnQT}}</span>
										</td>
									</tr>
								</tbody>
								
								<tr>
									<td colspan="4">
										<div class="titleItem">
											<strong>Remark#</strong>
											<span>
												{{allData.remark}}
											</span>
										</div>
									</td>
								</tr>
								
							</table>
						</div> -->
			
						<div class="list-prt-con">
			
							<table class="simTable printerTable">
								
								<thead>
									<tr>
										<th width="200">{{$t('i18nn_8758fd50c87d6c9c')}}</th>
										<th width="50">操作</th>
										<!-- <th width="50">{{$t('i18nn_28a07becf88aa5f5')}}</th> -->
										<th width="50">合计</th>
										<th width="100">{{$t('i18nn_8fa2f6bbb0d5b697')}}</th>
										<th width="50">{{$t('f9db93b87e08763b')}}</th>
										
									</tr>
								</thead>
								
								<tbody class="text_small" v-for="(item,index) in priterData.totalDetailArr" :key="index">
									<tr v-for="(item2, index2) in item.planList" :key="index2">
										<td :rowspan="item.planList.length" v-if="0==index2">
											<div><strong>{{ item.trAddrCode }}</strong></div>
											<div>{{ item.address }}</div>
											<div class="">
												<span>Remark#</span>
												<strong>{{ item.remark }}</strong>
											</div>
										</td>
										<td :rowspan="item.planList.length" v-if="0==index2">
											<div>
												<span>{{$t('i18nn_f9f65e4fadee5183')}}</span>:<span>{{ item.trWayName }}</span>
											</div>
											<div>
												<span>{{$t('i18nn_28a07becf88aa5f5')}}</span>:
												<strong v-if="'1'==item.isChangeLabel">
													{{ item.isChangeLabelName }}
												</strong>
												<span v-else>
													{{ item.isChangeLabelName }}
												</span>
											</div>
										</td>
										<!-- <td :rowspan="item.planList.length" v-if="0==index2">
											
										</td> -->
										<td :rowspan="item.planList.length" v-if="0==index2">
											<div>
												<span>{{$t('i18nn_c8773d8d74202801')}}</span>:<span>{{ item.pallteCount }}</span>
											</div>
											<div>
												<span>总数</span>:<span>{{ item.totalCTN }}</span>
											</div>
										</td>
										<td>
											{{ item2.ctnMark }}
										</td>
										<td>
											{{ item2.quantity }}
										</td>
										
									</tr>
									
									<!-- <tr>
										<td colspan="6">
											<div class="">
												<span>Remark#</span>
												<strong>{{ item.remark }}</strong>
											</div>
										</td>
									</tr> -->
									
								</tbody>
								
								<!-- <tr>
									<td colspan="4">
										<div class="titleItem">
											<strong>{{index2+1}}.</strong>
										</div>
										<div class="titleItem">
											<strong>{{ item2.planNo }}</strong>
										</div>
										
										<div class="titleItem">
											<span>是否FBA:</span>
											<strong>
												<span v-if="'1'==item2.addrType">{{$t('i18nn_02ccd2cf723f9272')}}</span>
												<span v-else>{{$t('i18nn_e76d885ae1c74d4f')}}</span>
											</strong>
										</div>
										<div class="titleItem">
											<span>{{$t('i18nn_fa02547543cff0f7')}}</span>
											<strong>{{ item2.isChangeLabelName }}</strong>
										</div>
										
										<div class="titleItem">
											<span>{{$t('i18nn_e1e2b21c7b4b951f')}}</span>
											<strong>{{ item2.trWayName }}</strong>
										</div>
									</td>
								</tr> -->
								<!-- <tr>
									<td colspan="4">
										<div class="titleItem">
											<span>Address:</span>
											<strong>{{ item2.address }}</strong>
										</div>
									</td>
								</tr> -->
								<!-- <tbody class="text_small">
									<tr v-for="(item, index) in item2.planList" :key="index" style="">
										<td width="150"><span>Mark:&nbsp;</span><span>{{item.ctnMark}}</span></td>
										<td width="150">Qty:&nbsp;{{item.quantity}}</td>
										<td>
											Weight:&nbsp;{{item.totalWeight}}
										</td>
										<td>
											Volume:&nbsp;{{item.totalVol}}
										</td>
									</tr>
								</tbody> -->
								<!-- <tr>
									<td>
										<div class="">
											<span>Pallte：</span>
											<strong>{{ item2.pallteCount }}</strong>
										</div>
									</td>
									<td>
										<div class="">
											<span>Total CTN：</span>
											<strong>{{ item2.totalCTN }}</strong>
										</div>
									</td>
									<td>
										<div class="">
											<span>Total Weight：</span>
											<strong>{{ item2.totalWeight }}</strong>
										</div>
									</td>
									<td>
										<div class="">
											<span>Total Volume：</span>
											<strong>{{ item2.totalVol }}</strong>
										</div>
									</td>
								</tr> -->
								<!-- <tr>
									<td colspan="4">
										<div class="titleItem">
											<span>Remark#</span>
											<strong>{{ item2.remark }}</strong>
										</div>
									</td>
								</tr> -->
								
								<tbody>
									<tr>
										<td colspan="6">
											<span>是否现场卸货?</span>
											<strong style="padding: 0 4mm;">Yes</strong>
											<strong style="padding: 0 4mm;">No</strong>
											<!-- <span>
												{{ priterData.isLiveUploadName }}
											</span> -->
											
										</td>
									</tr>
								</tbody>
								<tbody >
									<tr>
										<td colspan="6">
											<span>Remark#&nbsp;</span>
											<strong class="pre-text">{{priterData.remark}}</strong>
										</td>
									</tr>
								</tbody>
							</table>
							
							
							
						</div>
						
						<!-- <div class="titleItem">
							<span>{{$t('i18nn_e137950d1897817e')}}</span>
							<strong class="pre-text">{{ allData.remark }}</strong>
						</div> -->
						
						<!-- <div class="titleItem">
							<span>Remark#</span>
							<strong class="pre-text">{{ allData.remark }}</strong>
						</div> -->
						
						<div class="tableFooter">
							<ul>
								<li>Picking</li>
								<li>Check</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- 增值服务 -->
			
			<div style="height: 10px;" class="no_print"></div>
			<div v-show="isShowValueAdded" v-if="priterData.workOrderDtos && priterData.workOrderDtos.length>0">
				<div class="printer_break"></div>
				<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="priterData.workOrderDtos"></whPrinterValueAddListSub>
			</div>
		</div>
		

		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
		<!-- </el-dialog> -->
		
		
	<!-- </div> -->
</template>
<script>
	// import Vue from 'vue';
	// import Print from '@/plugins/print.js';
	// Vue.use(Print); // 注册
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';

	import barcode from '@/components/Common/barcode.vue';
	import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';
	export default {
		metaInfo() {
		  return {
		    title: this.priterData && this.priterData.packingNo?this.priterData.packingNo+'-Devanning-List':'HYTX-Devanning-List',
		  }
		},
		props: {
			isShowValueAdded: {
			  type: Boolean
			},
		},
		components: {
			barcode,
			whPrinterValueAddListSub
		},
		data() {
			return {
				filterData: {
					id:"",
					// batchNo: '',
					// exprStatus: ''
				},
				// allData: [],
				// hasDet: '0',
				// listUrl: [],
				loading_load: false,
				priterData: {},
				ValueAddOpenTime:""
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			if (this.$route.query && this.$route.query.id) {
				this.filterData.id = this.$route.query.id;
			}
			// if (this.$route.query && this.$route.query.exprStatus) {
			// 	this.filterData.exprStatus = this.$route.query.exprStatus;
			// }
			// if (this.$route.query && this.$route.query.hasDet) {
			// 	this.hasDet = this.$route.query.hasDet;
			// }

			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData();
			},
			// //打印
			// printerAction() {
			//   window.print();
			//   // this.$print(this.$refs.print);
			// },
			// exportExcel() {
			// 	let excelName = this.$t('i18nn_336569a8af640d81');
			// 	let excelOption = {
			// 		height: 20
			// 	};
			// 	let selHead = [{
			// 		title: this.$t('i18nn_18d7892498938435'),
			// 		key: 'cusName'
			// 	}, {
			// 		title: 'SKU',
			// 		key: 'goodsSku'
			// 	}, {
			// 		title: 'SKU中文标题',
			// 		key: 'goodsName'
			// 	}, {
			// 		title: 'SKU英文标题',
			// 		key: 'goodsNameEn'
			// 	}, {
			// 		title: this.$t('f9db93b87e08763b'),
			// 		key: 'totalSku'
			// 	}, {
			// 		title: this.$t('hytxs0000035'),
			// 		key: 'placeAll'
			// 	}];
				
			// 	let selHead2 = [{
			// 		title: this.$t('i18nn_18d7892498938435'),
			// 		key: 'cusName'
			// 	}, {
			// 		title: this.$t('ae61193590bd513d'),
			// 		key: 'orderNumber'
			// 	}, {
			// 		title: this.$t('e44e9a291d80c593'),
			// 		key: 'name'
			// 	}, {
			// 		title: 'SKU',
			// 		key: 'goodsSku'
			// 	}, {
			// 		title: this.$t('f9db93b87e08763b'),
			// 		key: 'quantity'
			// 	}, {
			// 		title: this.$t('hytxs0000035'),
			// 		key: 'place'
			// 	}, {
			// 		title: this.$t('i18nn_599710d641d92957'),
			// 		key: 'packingNo'
			// 	}];
			// 	let excelData = [];
			// 	let excelData2 = [];

			// 	this.allData.map((item, index) => {
			// 		if(item.plSummary){
			// 			item.plSummary.map((item2, index2) => {
			// 				excelData.push({
			// 					cusName: item.cusName,
			// 					goodsSku: item2.goodsSku,
			// 					goodsName: item2.goodsName,
			// 					goodsNameEn: item2.goodsNameEn,
			// 					totalSku: item2.totalSku,
			// 					placeAll: item2.placeAll,
			// 				});
			// 			});
			// 		}
					
			// 		if(item.orders){
			// 			item.orders.map((item2, index2) => {
			// 				if(item2.plReocrd){
			// 					item2.plReocrd.map((item3, index3) => {
			// 						excelData2.push({
			// 							cusName: item.cusName,
			// 							orderNumber: item2.orderNumber,
			// 							name: item2.name,
			// 							goodsSku: item3.goodsSku,
			// 							quantity: item3.quantity,
			// 							place: item3.place,
			// 							packingNo: item3.packingNo,
			// 						});
			// 					});
			// 				}
			// 			});
			// 		}
			// 	})
			// 	// console.log('selHead', selHead);
			// 	// excelUtilsNew.exportExcel(selHead, this.excelData, this.excelName, this.excelOption);
			// 	let excelSheetData = [{
			// 		sheetName: this.$t('i18nn_743322012e31b427'),
			// 		columns: selHead,
			// 		Data: excelData
			// 	}, {
			// 		sheetName: this.$t('e6bdd31025bb4f9f'),
			// 		columns: selHead2,
			// 		Data: excelData2
			// 	}];
			// 	excelUtilsNew.exportExcelForMultiSheet(excelSheetData, excelName, excelOption);

			// },
			//请求分页数据
			getPageData() {
				// let parm = [];
				// parm = this.filterData.sendId.split(",");
				this.loading_load = true;

				this.$http
					.get(this.$urlConfig.WhTransferFastPrinterDevanning+"/"+this.filterData.id, {})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code && data.data) {
							//导出用
							this.priterData = data.data;
							
							//增值服务
							if(this.priterData && this.priterData.workOrderDtos && this.priterData.workOrderDtos.length>0){
								this.$emit('ValAddUpdate',{
									val: this.priterData.workOrderDtos.length,
									show: true,
								});
							} else {
								this.$emit('ValAddUpdate',{
									val: 0,
									show: true,
								});
							}
							this.ValueAddOpenTime = new Date().getTime();
							//表格显示数据
							// if (1 == this.hasDet) {
							// 	this.priterData = data.data.prints;
							// } else {
							// 	let prints = data.data.prints;
							// 	prints.forEach(item => {
							// 		item.orders = [];
							// 	})
							// 	this.priterData = prints;
							// }
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">


</style>
<style scoped lang="less">
	// @import '@/assets/css/printer.less'
	@import url(../assets/css/printer.less);
</style>
